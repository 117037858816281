import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/blog',
    component: ComponentCreator('/blog', 'e64'),
    exact: true
  },
  {
    path: '/blog/archive',
    component: ComponentCreator('/blog/archive', '182'),
    exact: true
  },
  {
    path: '/blog/authors',
    component: ComponentCreator('/blog/authors', '0b7'),
    exact: true
  },
  {
    path: '/blog/changelog',
    component: ComponentCreator('/blog/changelog', 'fb7'),
    exact: true
  },
  {
    path: '/blog/how-epanet-algorithm-works',
    component: ComponentCreator('/blog/how-epanet-algorithm-works', '423'),
    exact: true
  },
  {
    path: '/confirm-subscription',
    component: ComponentCreator('/confirm-subscription', '4ef'),
    exact: true
  },
  {
    path: '/items',
    component: ComponentCreator('/items', '2ce'),
    exact: true
  },
  {
    path: '/thank-you',
    component: ComponentCreator('/thank-you', 'e20'),
    exact: true
  },
  {
    path: '/welcome',
    component: ComponentCreator('/welcome', '7eb'),
    exact: true
  },
  {
    path: '/welcome-back',
    component: ComponentCreator('/welcome-back', '4d5'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', 'eef'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', 'bb0'),
        routes: [
          {
            path: '/docs',
            component: ComponentCreator('/docs', '60a'),
            routes: [
              {
                path: '/docs/advanced/adv-water-quality/intro',
                component: ComponentCreator('/docs/advanced/adv-water-quality/intro', 'd28'),
                exact: true,
                sidebar: "advanced"
              },
              {
                path: '/docs/advanced/live-modelling/intro',
                component: ComponentCreator('/docs/advanced/live-modelling/intro', 'a5b'),
                exact: true,
                sidebar: "advanced"
              },
              {
                path: '/docs/advanced/modeling-algorithms/intro',
                component: ComponentCreator('/docs/advanced/modeling-algorithms/intro', '4de'),
                exact: true,
                sidebar: "advanced"
              },
              {
                path: '/docs/advanced/transients/intro',
                component: ComponentCreator('/docs/advanced/transients/intro', '03c'),
                exact: true,
                sidebar: "advanced"
              },
              {
                path: '/docs/fundamentals/calibration/intro',
                component: ComponentCreator('/docs/fundamentals/calibration/intro', 'f25'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/demands/intro',
                component: ComponentCreator('/docs/fundamentals/demands/intro', '0d6'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/field-testing/intro',
                component: ComponentCreator('/docs/fundamentals/field-testing/intro', '594'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/hydraulics/intro',
                component: ComponentCreator('/docs/fundamentals/hydraulics/intro', '7e2'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/model-building/intro',
                component: ComponentCreator('/docs/fundamentals/model-building/intro', 'abe'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/operations/intro',
                component: ComponentCreator('/docs/fundamentals/operations/intro', '032'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/planning/intro',
                component: ComponentCreator('/docs/fundamentals/planning/intro', '209'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/fundamentals/water-quality/intro',
                component: ComponentCreator('/docs/fundamentals/water-quality/intro', '254'),
                exact: true,
                sidebar: "fundamentals"
              },
              {
                path: '/docs/getting-started/',
                component: ComponentCreator('/docs/getting-started/', '8c4'),
                exact: true,
                sidebar: "tutorial"
              },
              {
                path: '/docs/getting-started/software',
                component: ComponentCreator('/docs/getting-started/software', '2de'),
                exact: true,
                sidebar: "tutorial"
              },
              {
                path: '/docs/getting-started/water-models',
                component: ComponentCreator('/docs/getting-started/water-models', '21b'),
                exact: true,
                sidebar: "tutorial"
              },
              {
                path: '/docs/sample/',
                component: ComponentCreator('/docs/sample/', '906'),
                exact: true
              },
              {
                path: '/docs/scripting/epanet-toolkit/intro',
                component: ComponentCreator('/docs/scripting/epanet-toolkit/intro', '347'),
                exact: true,
                sidebar: "scripting"
              },
              {
                path: '/docs/scripting/infoworks-ruby/intro',
                component: ComponentCreator('/docs/scripting/infoworks-ruby/intro', '159'),
                exact: true,
                sidebar: "scripting"
              },
              {
                path: '/docs/scripting/javascript/intro',
                component: ComponentCreator('/docs/scripting/javascript/intro', '000'),
                exact: true,
                sidebar: "scripting"
              },
              {
                path: '/docs/scripting/model-build/intro',
                component: ComponentCreator('/docs/scripting/model-build/intro', 'c17'),
                exact: true,
                sidebar: "scripting"
              },
              {
                path: '/docs/scripting/python/intro',
                component: ComponentCreator('/docs/scripting/python/intro', '768'),
                exact: true,
                sidebar: "scripting"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', 'e5f'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
